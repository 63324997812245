export const isFont = data => data && !!data.editorKey;
export const overrideSiteFontIfNeeded = (
  font,
  siteTextPresets,
  isSettingsPanel,
) => {
  if (isFont(font)) {
    const siteFont = Object.values(siteTextPresets).find(
      ({ editorKey }) => editorKey === font.editorKey,
    ) as any;
    const overloadedFont = {
      ...siteFont,
      ...font,
      family: siteFont.fontFamily,
    };
    overloadedFont.style = {
      bold: overloadedFont.weight === 'bold',
      italic: overloadedFont.style === 'italic',
    };
    const stringToTrim = 'font:';
    if (
      overloadedFont.value &&
      overloadedFont.value.indexOf(stringToTrim) === 0
    ) {
      overloadedFont.value = overloadedFont.value.substring(
        stringToTrim.length,
      );
    }
    return isSettingsPanel ? overloadedFont : JSON.stringify(overloadedFont);
  }
  return font;
};
