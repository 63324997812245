import * as React from 'react';
import { WidgetApp } from './components/WidgetApp/WidgetApp';
import { withStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import { overideSiteDate } from '../Shared/appKeys/override-site-data/override-site-data';

export const ComponentNoSentry = withStyles(WidgetApp, {
  ltrCssPath: 'widgetComponent.stylable.bundle.css',
  rtlCssPath: 'widgetComponent.rtl.min.css',
});

export const ComponentNoSentryWithAppSettingData = props => {
  const defaultOverrideData = {};
  const { siteTextPresets, siteColors } = props.style;

  for (const key in props.settingsDefaultData) {
    const defaultData = props.settingsDefaultData[key];

    defaultOverrideData[key] = overideSiteDate(
      defaultData,
      siteTextPresets,
      siteColors,
      false,
    );
  }

  const settingsData = {
    ...props.settingsDefaultData,
    ...defaultOverrideData,
    ...props.settingsUserData,
  };

  props.style.styleParams = {
    colors: settingsData,
    booleans: settingsData,
    fonts: settingsData,
    numbers: settingsData,
  };

  const newProps = {
    ...props,
    settingsData,
  };
  return <ComponentNoSentry {...newProps} />;
};
