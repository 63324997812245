import * as React from 'react';

export interface Experiments {
  isWidgetPhaseOnePointFiveEnabled?: boolean;
}

export const experimentsSpecMap = {
  isWidgetPhaseOnePointFiveEnabled: 'se_wixBooking_WidgetPhase1_5FT',
};

function isExperimentsTrue(experimentValue) {
  return experimentValue === 'true' || experimentValue === 'new';
}

function mapExperiments(experiments: any): Experiments {
  const mappedExperiments = {};

  Object.entries(experimentsSpecMap).forEach(entry => {
    const experimentEnablerName = entry[0];
    const experimentSpec = entry[1];
    mappedExperiments[experimentEnablerName] = isExperimentsTrue(
      experiments[experimentSpec],
    );
  });

  return mappedExperiments;
}

export interface ExperimentsContext {
  experiments: any;
}

export interface ExperimentsProps {
  experiments?: Experiments;
}

const experimentsContext = React.createContext<ExperimentsContext>(null);

export const ExperimentsContextProvider = experimentsContext.Provider;

export const ExperimentsContextConsumer = experimentsContext.Consumer;

export const withExperimentsContext = <TProps extends object>(
  WrappedComponent: React.ComponentType<TProps>,
) =>
  class withBiLoggerContextComponent extends React.Component<
    TProps & ExperimentsProps
  > {
    render() {
      return (
        <ExperimentsContextConsumer>
          {(context: ExperimentsContext) => {
            const experimentsProps = {
              experiments: mapExperiments(context.experiments),
            };
            return <WrappedComponent {...this.props} {...experimentsProps} />;
          }}
        </ExperimentsContextConsumer>
      );
    }
  };
