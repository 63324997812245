import { isFont, overrideSiteFontIfNeeded } from './override-site-font';
import { isColor, overrideSiteColorIfNeeded } from './override-site-color';

export const overideSiteDate = (
  data,
  siteTextPresets,
  siteColors,
  isSettingsPanel,
) => {
  if (isFont(data)) {
    return overrideSiteFontIfNeeded(data, siteTextPresets, isSettingsPanel);
  }

  if (isColor(data)) {
    return overrideSiteColorIfNeeded(data, siteColors);
  }
  return data;
};
